import $ from "jquery";
import barba from "@barba/core";

function search() {
  $(".navbar .search").on("click", function () {
    $("body").toggleClass("search-open");
    setTimeout(function () {
      $("#search").focus();
    }, 100);
  });

  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      $("body").removeClass("search-open");
    }
  });

  $("form").submit(function (e) {
    e.preventDefault();
    const formAction = $(this).attr("action");
    const formFields = $(this).serialize();
    barba.go(`${formAction}?${formFields}`);
    return false;
  });
}

export { search };
