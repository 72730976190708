import Cookies from "js-cookie";

function darkMode() {
  const toggleSwitch = document.querySelectorAll(
    '.theme-switch input[type="checkbox"]',
  );
  const currentTheme = Cookies.get("theme");

  if (currentTheme) {
    document.documentElement.setAttribute("data-theme", currentTheme);

    if (currentTheme === "dark") {
      toggleSwitch.checked = true;
    }
  }

  function switchTheme(e) {
    if (e.target.checked) {
      document.documentElement.setAttribute("data-theme", "dark");
      Cookies.set("theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      Cookies.set("theme", "light");
    }
  }

  for (let i = 0; i < toggleSwitch.length; i++) {
    toggleSwitch[i].addEventListener("change", switchTheme, false);
  }
}

export { darkMode };
