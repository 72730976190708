import { search } from "@modules/search";

export default [
  {
    namespace: "home",
    beforeEnter() {
      search();
    },
  },
  {
    namespace: "search",
    beforeEnter() {
      search();
    },
  },
  {
    namespace: "archive-post",
    beforeEnter() {
      search();
    },
  },
];
